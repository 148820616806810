export const variants = {
  COMMON: {
    volume: 400,
    price: 500,
  },

  LARGE: {
    volume: 500,
    price: 900,
  },
};
