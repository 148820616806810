export const stepCardsData = [
  {
    key: "instruction_1",
    description: <>Перед применением тщательно взболтайте флакон.</>,
    additionalDescription: [
      <>Присадку рекомендуется применять до заправки транспортного средства</>,
      <>
        Присадка представляет собой высококонцентрированный вязкий полимерный
        раствор для разбавления в топливе в рекомендованной концентрации
      </>,
      <>Флакон 400 мл рассчитан на 200–400 литров топлива</>,
    ],
  },

  {
    key: "instruction_2",
    description: (
      <>
        Откройте флакон и, пользуясь мерной шкалой сбоку флакона, залейте
        требуемое количество присадки в топливный бак
      </>
    ),
    additionalDescription: [
      <>
        Для расчета требуемого количества присадки вы можете воспользоваться
        калькулятором расположенным ниже на сайте
      </>,
      <>
        Рекомендуемая дозировка присадки составляет 1-2мл реагента на 1л топлива
      </>,
      <>
        Даже тройное превышение рекомендуемой дозировки не оказывает негативного
        влияния на работу топливной системы
      </>,
      <>Допускается небольшая нитевидность при наливе</>,
    ],
  },

  {
    key: "instruction_3",
    description: (
      <>
        Вставьте заправочный пистолет и залейте ДТ в топливный бак транспортного
        средства
      </>
    ),
    additionalDescription: [
      <>
        Через 5–10 мин после полного растворения в баке присадка начинает
        действовать
      </>,
      <>
        Присадка является реагентом постоянного применения, поэтому её
        необходимо добавлять в топливный бак во время каждой заправки автомобиля
      </>,
    ],
  },
];
